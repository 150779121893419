export const environment = {
	production: true,
	SHARE_URL: 'https://infoday.io',
	COOKIE_SECURE: true,
	COOKIE_DOMAIN: 'https://infoday.io',
	LinkedInClientId: '78hfudv1z68ye9',
	LinkedInClientSecret: 'KVR516NeKYKrRklG',
	LinkedInRedirectUrlRegister: 'https://infoday.io/register',
	LinkedInRedirectUrlLogin: 'https://infoday.io/login',
	API_URL: 'https://api.espresso-tutorials.com/',
	TRACING: 0,
	VERSION: require('../../package.json').version,
	pusher: '01hzppy59nvpxgdcgk1yj9zgfs',
	pusherCluster: 'eu',
	pusherAppId: '01hzppykq0t11c7aszy2mxy4v0',
};
